import { Form, Formik } from 'formik';
import { useRouter } from 'next/dist/client/router';
import React, { FC, useContext, useState, useEffect } from 'react';
import * as yup from 'yup';
import usePushFormikFormToDataLayer from '../../../hooks/use-push-formik-form-to-data-layer/use-push-formik-form-to-data-layer';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { LoginType } from '../../../models/login-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import FormText from '../../atoms/form-text/form-text';
import TextLink from '../../atoms/text-link/text-link';
import LoginDuplicateEmailModal from '../../molecules/login-duplicate-email-modal/login-duplicate-email-modal';
import open from '../../providers/overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import useLogin from '../../providers/user/hooks/use-login.hook';
import useUser from '../../providers/user/hooks/use-user.hook';

const Login: FC<LoginType> = (props: LoginType) => {
  const {
    accountOnStopMessage,
    forgotDetailsLink,
    loginButtonText,
    loginRedirectLink,
    passwordFieldLabel,
    passwordFieldMandatoryError,
    usernameFieldLabel,
    usernameFieldMandatoryError,
  } = props;

  const overylayDispatch = useContext(OverlayDispatchContext);
  const login = useLogin();
  const router = useRouter();
  const user = useUser();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [hasLoggedIn, setHasLoggedIn] = useState<boolean>(false);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const openDuplicateEmailModal = (): void => {
    overylayDispatch(open(<LoginDuplicateEmailModal {...props} />));
  };

  const handleLoginErrorDataLayer = (errorMessage: string) => {
    pushToDataLayer(GtmEventNameEnum.LoginFail, { errorMessage });
  };

  const formContext = usePushFormikFormToDataLayer(
    'Login',
    hasLoggedIn && !!user
  );

  useEffect(() => {
    if (hasLoggedIn && !!user) {
      pushToDataLayer(GtmEventNameEnum.LoginSuccess);
      router.push(loginRedirectLink.url);
    }
  }, [hasLoggedIn, !!user]);

  return (
    <div className="pb-component-spacing-mobile lg:pb-component-spacing flex flex-col items-center">
      <div className="w-full md:w-96">
        <Formik
          innerRef={formContext}
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={async (values, { setErrors }) => {
            try {
              setIsLoggingIn(true);
              await login(values.username, values.password);
              setHasLoggedIn(true);
            } catch (error) {
              if (error?.response?.status === 409) {
                openDuplicateEmailModal();
                setIsLoggingIn(false);
                handleLoginErrorDataLayer('Duplicate email');

                return;
              }

              if (
                error?.response?.status === 403 &&
                error?.response?.data?.status === 'OnHold'
              ) {
                setErrors({ username: accountOnStopMessage });
                setIsLoggingIn(false);

                handleLoginErrorDataLayer(accountOnStopMessage);

                return;
              }

              const errors = error.response?.data?.invalid_grant
                ? { password: error.response?.data?.invalid_grant }
                : {
                    username:
                      'An unknown login error occurred. Please try again.',
                  };

              setErrors(errors);
              setIsLoggingIn(false);

              handleLoginErrorDataLayer(errors.password || errors.username);
            }
          }}
          validationSchema={yup.object().shape({
            username: yup
              .string()
              .required(usernameFieldMandatoryError)
              .trim(usernameFieldMandatoryError),
            password: yup
              .string()
              .required(passwordFieldMandatoryError)
              .trim(passwordFieldMandatoryError),
          })}
        >
          <Form className="flex flex-col space-y-5">
            <FormText name="username" label={usernameFieldLabel} />

            <FormText
              type="password"
              name="password"
              label={passwordFieldLabel}
            />

            <div className="pt-6 mx-auto flex flex-col items-center space-y-5 w-full">
              <ButtonPrimary
                buttonType="submit"
                name={loginButtonText}
                disabled={isLoggingIn}
              />

              <TextLink
                className="inline-block mx-auto"
                {...forgotDetailsLink}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
