import React, { FC, useContext } from 'react';
import { LoginType } from '../../../models/login-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import Modal from '../modal/modal';

const LoginDuplicateEmailModal: FC<Partial<LoginType>> = ({
  duplicateEmailMessageConfirmButton,
  duplicateEmailMessageTitle,
  duplicateEmailsMessage,
}: Partial<LoginType>) => {
  const overlayDispatch = useContext(OverlayDispatchContext);

  return (
    <Modal medium>
      <div className="flex flex-col items-center space-y-8">
        <h4 className="page-heading-four">{duplicateEmailMessageTitle}</h4>

        <p className="mt-1 text-grey-600 max-w-lg">{duplicateEmailsMessage}</p>

        <ButtonPrimary
          className="mb-6 md:mb-0 md:pt-2"
          name={duplicateEmailMessageConfirmButton}
          onClick={() => overlayDispatch(close())}
        />
      </div>
    </Modal>
  );
};

export default LoginDuplicateEmailModal;
