import dayjs from 'dayjs';
import { useContext } from 'react';
import accountSession from '../../../../services/customer-api/endpoints/account/session';
import oauthToken, {
  OauthGrantType,
} from '../../../../services/customer-api/endpoints/oauth/token';
import updateAuth from '../actions/update-auth';
import { UserDispatchContext } from '../user.provider';

const useLogin = (): ((
  username: string,
  password: string
) => Promise<void>) => {
  const userDispatch = useContext(UserDispatchContext);

  return async (username: string, password: string) => {
    const response = await oauthToken({
      grantType: OauthGrantType.Password,
      username: `${process.env.NEXT_PUBLIC_DOMAIN}/${username}`,
      password,
    });

    const { sessionId } = await accountSession(response.access_token);

    userDispatch(
      updateAuth({
        accessToken: response.access_token,
        accessTokenExpiresAt: dayjs()
          .add(response.expires_in, 'second')
          .toISOString(),
        refreshToken: response.refresh_token,
        sessionId,
      })
    );
  };
};

export default useLogin;
